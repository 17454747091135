<template>
    <div class="content">
<div class="top">
  <div class="title">
    <img style="width: calc(100vw * 385 / 1920);height: calc(100vw * 186 / 1920);" src="@/assets/img/logo.png" alt="">
  </div>
  <div class="logo">
    <img style="width: calc(100vw * 177 / 1920);height: calc(100vw * 64 / 1920);" src="@/assets/img/gsLOGO.png" alt="">
  </div>
</div>

<div class="main" >
  <template v-for="(item,index) in prompt">
<!-- <div> -->
<div class="left1" v-if="item.role=='assistant'">
  <img style="margin-right: calc(100vw * 30 / 1920);width: calc(100vw * 100 / 1920);height: calc(100vw * 100 / 1920);" src="@/assets/img/avatar1.png" alt="">
  <div class="left" v-html="nr(item.content)" >
    
    
</div>
</div>

<div class="right1" v-if="item.role=='user'">
  <div class="right" >
    {{ filtration(item.content) }}
</div>
<img style="margin-left: calc(100vw * 30 / 1920);width: calc(100vw * 100 / 1920);height: calc(100vw * 100 / 1920);" src="@/assets/img/avatar2.png" alt="" >

</div>
<!-- </div> -->
  
  </template>
  
  <div class="left1"  v-if="event=='add'" >
  <img style="margin-right: 30px;width: calc(100vw * 100 / 1920);height: calc(100vw * 100 / 1920);" src="@/assets/img/avatar1.png" alt="">
  <div class="left" v-text="message" >

</div>
</div>
 <!-- <div class="stream" v-if="event=='add'" v-text="message"></div> -->

</div>
<div class="bottom">
  <!-- <div class="left"> -->
    <img @click.stop="help" style="width: calc(100vw * 275 / 1920);height: calc(100vw * 68 / 1920);" src="@/assets/img/pop.png" alt="">
  <!-- </div> -->
  <div style="position: relative;">
    <img @click.stop="ask" id="startBtn" style="width: calc(100vw * 976 / 1920);height: calc(100vw * 171 / 1920);" v-show="!flag" src="@/assets/img/voice.png" alt="">
    <div v-show="!flag"  style="position: absolute;bottom: calc(100vw * 15 / 1920);left: 50%;transform: translate(-50%,0);font-size: calc(100vw * 28 / 1920);">语音识别</div>
  </div>
  <!-- <div class="right"> -->
    <img class="clear" style="width: calc(100vw * 189 / 1920);height: calc(100vw * 68 / 1920);" @click.stop="clearHistory" src="@/assets/img/btn.png" alt="">

  <!-- </div> -->
</div>


<div :class="flag?'mask':'masks'">
  <div class="mask1">
 
    <div class="top" >
      {{ content }}
      <div>正在识别中...</div>
      <div class="countdown">{{ countdown }}</div>
      <div class="tips">
            <img style="width: calc(100vw * 32 / 1920);height: calc(100vw * 32 / 1920);" src="@/assets/img/tips.png" alt="">
            <span>点击确定后即刻反馈结果</span>
          </div>
    </div>
    <div class="btn">
      <img @click="cancelBtn" style="width: calc(100vw * 69 / 1920);height: calc(100vw * 28 / 1920);"  src="@/assets/img/cancel.png" alt="">
      <img @click="confirmBtn"  style="width: calc(100vw * 67 / 1920);height: calc(100vw * 28 / 1920);" ref="endBtn" id="endBtn" src="@/assets/img/conform.png" alt="">
    </div>
    <!-- <div class="img">
      <img src="@/assets/img/voice_pic.png" alt="">
    </div> -->
    <div class="bottom">
  <!-- <div class="left"> -->
    <img style="opacity: 0;width: calc(100vw * 275 / 1920);height: calc(100vw * 68 / 1920);" @click.stop="help" src="@/assets/img/pop.png" alt="">
  <!-- </div> -->
  <div style="position: relative;">
    <img style="width: calc(100vw * 976 / 1920);height: calc(100vw * 171 / 1920);" src="@/assets/img/voice_pic.png" alt="">
    <div style="position: absolute;bottom: calc(100vw * 15 / 1920);left: 50%;transform: translate(-50%,0);font-size: calc(100vw * 28 / 1920);">语音识别</div>
  </div>
  <!-- <div class="right"> -->
    <img style="opacity: 0;width: calc(100vw * 189 / 1920);height: calc(100vw * 68 / 1920);"  class="clear" @click.stop="clearHistory" src="@/assets/img/btn.png" alt="">

  <!-- </div> -->
</div>
  </div>
</div>


<div class="maskFlag" v-if="maskFlag">
  <div class="mask1">

    <div class="img">
      <img style="width: calc(100vw * 100 / 1920);height: calc(100vw * 100 / 1920);" src="@/assets/img/avatar1.png" alt="">
    </div>
    <div class="keywords">
      <div>可以通过语句向我描述要求，生成藏头诗</div>
    <div class="two">如：请用<span>平湖秋月</span>写一首藏头诗</div>
    </div>
    <div class="confirm" @click="okBtn">好的</div>
  </div>
</div>
</div>

</template>

<script>
import minix from '@/minix'

export default {
  mixins: [minix],
  data(){
    return{
      xitong:'anquanzhan',
      maskFlag:false,
      flag:false,
      eventSource:null,
      message:'',
      content:'',
      signSucc:'',
      prompt:[

      ],
      event:''
    }
  },

  mounted() {
    this.audioInit()
  },
  methods: {

   
  }
}
</script>
<style lang="scss">
@import '@/assets/css/style.css';
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body,html{
  height: 100%;
}
.maskFlag{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: .6);
  display: flex;
  align-items: center;
  justify-content: center;
  .mask1{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 40%;
    height: 30%;
    background: #082348;
border-radius: calc(100vw * 25 / 1920);
opacity: 1;
border: 1px solid #FFFFFF;
position: relative;
.img{
position: absolute;
top: calc(100vw * -50 / 1920);
left: 50%;
transform: translate(-50%,0);
}
.keywords{
  margin-top: 4%;
  font-weight: 800;
color: #FFFFFF;
font-family: 'wangxingban';
font-size: calc(100vw * 28 / 1920);
text-align: center;
.two{
  font-weight: 800;
color: #B5BDC8;
line-height: calc(100vw * 47 / 1920);
span{
  color: #fff;
}
}
}
.confirm{
  margin-top: 5%;
  width: 20%;
height: 20%;
background: linear-gradient(319deg, #E0E5EC 0%, #D5E7F6 100%);
border-radius: calc(100vw * 47 / 1920);
opacity: 1;
text-align: center;
font-size: calc(100vw * 28 / 1920);
font-family: 'wangxingban';
font-weight: 800;
color: #082348;
display: flex;
justify-content: center;
align-items: center;
}
  }
}
.masks{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: .6);
  display: flex;
  align-items: end;
  justify-content: center;
  z-index: -99;
}
.mask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: .6);
  display: flex;
  align-items: end;
  justify-content: center;

}
.mask1{
  width: 100%;
  // padding: calc(100vw * 20 / 1920);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
.top{
  margin-left: calc(100vw * 90 / 1920);
  font-size: calc(100vw * 24 / 1920);
  font-family: 'wangxingban';
  padding: calc(100vw * 60 / 1920);
  min-width: calc(100vw * 593 / 1920);
    max-width: 80%;
    min-height: calc(100vw * 157 / 1920);
    background: linear-gradient(319deg, #B7DFFF 0%, #C6D6F3 100%);
border-radius:calc(100vw * 20 / 1920);
opacity: 1;
position: relative;
div{
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: 'wangxingban';
  padding: calc(100vw * 14 / 1920) calc(100vw * 25 / 1920);
  font-size: calc(100vw * 15 / 1920);
  color: #84A3C1;
}
}
.btn{
  margin-left: calc(100vw * 90 / 1920);
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    margin: calc(100vw * 15 / 1920) calc(100vw * 20 / 1920);
  }
}
  }
.content{
  font-family: 'CustomFont';

  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  padding: calc(100vw * 20 / 1920);
  padding-bottom: 0;
  background: url('@/assets/img/underpic.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='@/assets/img/underpic.png', sizingMethod='scale');
}
.top{
  height: 15%;
  // width:100%;
  position: relative;
  .title{
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%,0);
    width: calc(100vw * 322 / 1920);
    height: calc(100vw * 165 / 1920);
  }
  .logo{
    position: absolute;
    right: 30px;
    top: 0;
  }
}
.main::-webkit-scrollbar {
  	display: none;
}
.main{
flex: 1;
// height: 80%;
font-family: 'wangxingban';
overflow-y: scroll;
.left1{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.left{
  max-width: 40%;
 background: linear-gradient(319deg, #E0E5EC 0%, #FFFFFF 100%);
border-radius: calc(100vw * 38 / 1920) calc(100vw * 38 / 1920) calc(100vw * 38 / 1920) 0;
padding: calc(100vw * 20 / 1920) calc(100vw * 42 / 1920);
opacity: 1;
min-height: calc(100vw * 75 / 1920);
font-size: calc(100vw * 24 / 1920);
font-weight: 800;
color: #082348;
line-height: calc(100vw * 53 / 1920);
overflow: auto;
}
.right1{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.right{
  max-width: 40%;
  display: flex;
 justify-content: flex-end;
  padding: 20px 42px;
  background: linear-gradient(319deg, #C7DFF2 0%, #C6D6F3 100%);
border-radius: calc(100vw * 38 / 1920) calc(100vw * 38 / 1920) 0px calc(100vw * 38 / 1920);
opacity: 1;
min-height: calc(100vw * 75 / 1920);
font-size: calc(100vw * 24 / 1920);
font-weight: 800;
color: #082348;
line-height: calc(100vw * 53 / 1920);
}
.stream{

}
}

.bottom{
  height: calc(100vw * 171 / 1920);
  width:100%;
  // height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .clear{
    cursor: pointer;
  }
}
.tips {
  position: absolute;
  top: calc(100vw * -200 / 1920);
  left: 0;
  width: 100%;
  max-width: 80%;
  display: flex;
  font-family: 'sc_regular';
  align-items: center;

  img {
    width: calc(100vw * 32 / 1920);
    height: calc(100vw * 32 / 1920);
  }

  span {
    color: #FFFFFF;
    line-height: calc(100vw * 37 / 1920);
    font-size: calc(100vw * 20 / 1920);
  }
}
.countdown{
  position: absolute;
  top: 0;
  right: calc(100vw * 20 / 1920);
}
</style>


<!-- <template>
  <div id="app">
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    <router-view/>
  </div>
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style> -->
