// import { ElMessage, ElMessageBox } from 'element-plus'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
// import { useAuthStore } from '@/store/modules/auth'
// import { loginApi, refreshTokenApi } from '@/api/auth/'


let errFlag = false
let isRefreshing = false
const requestList= []
const baseURL = 'https://open.bigmodel.cn/api/paas/v3/model-api/chatglm_pro/async-invoke'
const quickRequest = axios.create({
  baseURL,
  timeout: 1000 * 30,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
})
const saveRequest = (callback) => {
  requestList.push(callback)
}
const handleRequest = (token) => {
  requestList.forEach((callback) => {
    callback(token)
  })
  requestList.length = 0
}

// 请求拦截器
quickRequest.interceptors.request.use(
  (config) => {
    // 登录请求
    // if (config.url === loginApi) {
    //   return config
    // }
    // token过期

    // if (isTokenExpire() && config.url !== refreshTokenApi) {
    //   if (!isRefreshing) {
    //     isRefreshing = true
    //     const authStore = useAuthStore()
    //     const quickRefreshToken = authStore.getRefreshToken
    //     authStore
    //       .refreshToken({ quickRefreshToken })
    //       .then(() => {
    //         const token = authStore.getAccessToken
    //         if (token) {
    //           handleRequest(token)
    //         }
    //       })
    //       .finally(() => {
    //         isRefreshing = false
    //       })
    //   }
    //   const retry = new Promise((resolve) => {
    //     saveRequest((token: string) => {
    //       const cfg = config
    //       if (cfg.headers) {
    //         cfg.headers.authorization = `Bearer ${token}`
    //       }
    //       resolve(cfg)
    //     })
    //   })
    //   return retry
    // }
    const cfg = config
    // const loginStore = useAuthStore()
    // const token = loginStore.getAccessToken
    // if (cfg.headers) {
    //   cfg.headers.token = token
    // }
    console.info('request', cfg)
    return cfg
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
quickRequest.interceptors.response.use(
  (res) => {
    console.info('response', res)
    const { data: resultData } = res
    // 导出文件
    if (res.config.responseType === ('arraybuffer' || 'blob')) {
      return resultData
    }
    
    const { data } = resultData
    console.log(resultData,'data');

    if (!data) {
      return Promise.resolve(resultData)
    }
    const { payload, total } = data
    if (payload) {
      return Promise.resolve({
        data: payload,
        total,
      })
    }
    return Promise.resolve({
      resultData,
    })
  },
  (error) => {
    const { response } = error
    const { status } = response
    if (status === 401) {
      // if (!errFlag) {
      //   ElMessageBox.confirm('登录过期，请重新登录', '警告', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning',
      //   })
      //     .then(() => {
      //       errFlag = true
      //       localStorage.clear()
      //       sessionStorage.clear()
      //       window.location.href = '/'
      //     })
      //     .catch(() => {
      //       errFlag = false
      //     })
      // }
    } else if (status === 400) {
      const { data: resultData } = response
      const { msg } = resultData
      // ElMessage.error(msg)
    } else {
      if (!errFlag) {
        errFlag = true
        // ElMessage.error(error)
      }
    }
    return Promise.reject(error)
  }
)

const request = (config)=> {
  return quickRequest.request(config)
}
export default request
