/*
 * @Description: 
 * @Version: 
 * @Autor: ping
 * @Date: 2023-08-01 22:43:03
 * @LastEditors: ping
 * @LastEditTime: 2023-08-01 23:19:17
 */
import request from '@/utils/request'
 //添加备案
 export const init = ()=> {
  return request({
    url: 'https://open.bigmodel.cn/api/paas/v3/model-api/chatglm_pro/async-invoke',
    method: 'POST',
    headers:{
      Authorization:'eyJhbGciOiJIUzI1NiIsInNpZ25fdHlwZSI6IlNJR04iLCJ0eXAiOiJKV1QifQ.eyJhcGlfa2V5IjoiZDljOTRiODQ0OWRkYzEyZjY0ZGU3NDc4Njk0NTNjOTkiLCJleHAiOjE2OTA4NjI1OTczNzUsInRpbWVzdGFtcCI6MTY5MDg1ODk5NzM3NX0.TqAwWlCsmRrLHatwp8chhI4BnV_4QBrrFQIlOa-PqTs'
    },
    data:{
      prompt:{
        role:'user',
        content:'今天星期几'
      },
     
    }
  })
}

export const init1 = (task_id)=> {
  return request({
    url: 'https://open.bigmodel.cn/api/paas/v3/model-api/-/async-invoke/'+task_id,
    method: 'get',
    headers:{
      Authorization:'eyJhbGciOiJIUzI1NiIsInNpZ25fdHlwZSI6IlNJR04iLCJ0eXAiOiJKV1QifQ.eyJhcGlfa2V5IjoiZDljOTRiODQ0OWRkYzEyZjY0ZGU3NDc4Njk0NTNjOTkiLCJleHAiOjE2OTA4NjI1OTczNzUsInRpbWVzdGFtcCI6MTY5MDg1ODk5NzM3NX0.TqAwWlCsmRrLHatwp8chhI4BnV_4QBrrFQIlOa-PqTs'
    },
    // params:{
    //   task_id
     
    // }
  })
}

export const audio =(Text,ShortName)=>{
  return request({
    url:'https://ai.twzc.zsvrsky.cn/api/generateAudioV2',
  headers:{
    SecretKey:'eyJhbGciOiJIUzI1NiIsInNpZ25fdHlwZSI6IlNJR04iLCJ0eXAiOiJKV1QifQ.eyJhcGlfa2V5IjoiZDljOTRiODQ0OWRkYzEyZjY0ZGU3NDc4Njk0NTNjOTkiLCJleHAiOjE2OTA4NjI1OTczNzUsInRpbWVzdGFtcCI6MTY5MDg1ODk5NzM3NX0.TqAwWlCsmRrLHatwp8chhI4BnV_4QBrrFQIlOa-PqTs'
  },
  method:'post',
  data:{
    Text,
    ShortName
  }
  })
}